.container-centered {
    display: flex;
    flex-direction: column; /* İçerikleri dikey olarak sırala */
    justify-content: center;
    align-items: center;
    height: 90vh; /* Viewport'un tam yüksekliği */
    text-align: center; /* Metni ortala */
}
.navbar {
    padding-top: 0%;
    padding-bottom: 0%;
    border: 1px solid #000; /* Siyah, 2 piksel kalınlığında bir alt border ekler */
}

.image-title {
    /*font-size: 96px;*/ 
    color: #333; 
    margin-top: 20px; 
}
.custom-section {
    padding-top: 10%;
    border-left: 0.1rem solid black;
    border-right: 0.1rem solid black;
    border-bottom: 0.1rem solid black; /* Her section'ın altına ince siyah bir çizgi ekler */
}
.custom-section-detail {
  padding-top: 10%;
  border-left: 0.1rem solid black;
  border-right: 0.1rem solid black;
  /*border-bottom: 0.1rem solid black; /* Her section'ın altına ince siyah bir çizgi ekler */
}
.accordion-item {
    border-right: 0;
    border-left: 0;
    &:last-child { border-bottom: 0; }
 }
hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: black; /* Çizginin rengini belirle */
    opacity: 1;
  }
  .accordion-button {
    font-size: 1.6rem; /* Küçük ekranlar için daha küçük font boyutu */
  }
  
  /* Orta boy ekranlar için font boyutunu artır */
  @media (min-width: 768px) {
    .accordion-button {
      font-size: 2.0rem; /* Orta boy ekranlar için orta font boyutu */
    }
  }
  
  /* Büyük ekranlar için font boyutunu daha da artır */
  @media (min-width: 992px) {
    .accordion-button {
      font-size: 2.4rem; /* Büyük ekranlar için büyük font boyutu */
    }
  }